<template>
  <div id="MapPanel" :class="`centerContent ${$screen.device}`" :style="{maxWidth:$ui.size.contentMaxWidth+'px'}">
      <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A3a6424e6f2fd46f3208202b162f43cc0e17caa6de9dff99940bfdd447a567c0d&amp;source=constructor" width="100%" :height="height" frameborder="0"></iframe>

      <div class="content">
          <div class="header"></div>
          <div class="backTexture" :style="{backgroundImage: `url(${require('@/assets/img/pattern.png')})`}"></div>
          <div class="contacts">
              <a href="tel:88002012725" class="contact number" >
                  <div  class="item">
                      <v-icon color="green" size="30">mdi-phone</v-icon><span>8 (800) 201 27 25</span>
                  </div>
                  <p class="label">ОТДЕЛ РАЗМЕЩЕНИЯ И БРОНИРОВАНИЯ</p>
              </a>
              <a href="mailto:info@san-elita.ru" class="contact email">
                  <div  class="item">
                      <v-icon color="green" size="30">mdi-email</v-icon><span>info@san-elita.ru</span>
                  </div>
                  <p class="label">ПО ВСЕМ ВОПРОСАМ</p>
              </a>
          </div>
          <div class="sociallinks">
              <a class="soclink" href="https://wa.me/79283002266" ><img height="50" :src="require('@/assets/img/whatsapp.svg')"/></a>
              <a class="soclink" href=""><img height="50" :src="require('@/assets/img/tg.svg')"/></a>
          </div>

      </div>

  </div>

</template>

<script>


  export default {
  name: 'MapPanel',
  data: () => ({
      mainMenuHeight:50,
      subMenuHeight:30,
  }),
  methods:{scrollUp(){window.scrollTo({top: 0, behavior: 'smooth'})}},
  mounted() {},
      computed: {
          height() {
              let baseZoom = 300
              if(this.$screen.device==='mobile'){baseZoom=200}
              return baseZoom
          }
      }
}
</script>

<style scoped>
    #MapPanel{display: grid;background-color: #444138;align-items: center;justify-items: center;overflow: hidden;width: 100%;grid-template-columns:3fr 1fr; margin-bottom: 4px}
    #MapPanel.mobile{grid-template-columns: 1fr;}
    #MapPanel.mobile>.content{order: -1}
    #MapPanel>.content{height: 100%;position: relative;align-content: center;display: grid;padding: 20px;row-gap: 10px;width: 100%;}
    .backTexture{position: absolute; z-index: 0;  background-repeat: repeat; width: 100%; height: 100%; zoom: 30%; opacity: 5%}

    #MapPanel>.content>.header{}
    #MapPanel>.content>.contacts{z-index: 1;}
    #MapPanel>.content>.sociallinks{z-index: 1;}


    #MapPanel>.content>.contacts>.contact{color: white; transition-duration: 100ms; cursor: pointer; font-weight: bold; font-size: 22px;  display: grid; width: 100%; overflow: hidden; border-radius: 10px; padding-top: 8px; align-content: center;  background-color: rgba(0, 0, 0, 0.40);}
    #MapPanel>.content>.contacts>.contact>.item{ white-space:nowrap; padding-left: 10px; padding-right: 10px; display: grid; align-items: center; grid-template-columns: auto auto;justify-content: center; column-gap: 10px;  }
    #MapPanel>.content>.contacts>.contact>.label{font-size: 10px; font-weight: normal; padding: 4px;  text-align: center; background-color: #312d2d}
    #MapPanel>.content>.contacts>.contact.email{margin-top: 10px}
    #MapPanel>.content>.contacts>.contact.email>.item{font-weight: lighter}

    #MapPanel>.content>.contacts>.contact:hover{background-color: rgba(87, 85, 85, 0.6);}


    .sociallinks{display: grid; grid-auto-flow: column; column-gap: 4px; justify-content: center;}
    .soclink{ color: white; display: grid; align-items: center;}
    .soclink>img{height: 50px; transition-duration: 300ms; }

    .soclink>img:hover{filter: brightness(115%)}

</style>

