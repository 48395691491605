<template>
  <div id="mainWindow" :style="{height:$screen.height+'px', width:$screen.width+'px'}">
      <scroll-up/>
      <nav-panel v-if="$screen.device!=='mobile'"/>
      <overlays-view />
      <mobile-view v-if="$screen.device==='mobile'"/>
      <main-view v-if="$screen.device!=='mobile'"/>
    <promo-block/>

</div>
</template>

<script>

  import MainView from "@/views/MainView";
  import MobileView from "@/views/MobileView";
  import OverlaysView from "@/views/OverlaysView";
  import NavPanel from "@/components/NavPanel";
  import ScrollUp from "@/components/ScrollUp";
  import PromoBlock from "@/components/PromoBlock";
  // import axios from "axios";
  export default {
  name: 'App',
    components: {
      PromoBlock,
        ScrollUp,
        NavPanel,
        OverlaysView,
        MobileView,
        MainView,},
    data: () => ({
        rooms:false,
    }),
  mounted() {
      if(this.$store.state.enterPoint === undefined){
          this.$commit('setEnterPoint', this.$route.path)
          this.$commit('updateMinPrice')

          // if(this.$route.path!=='/'){
          //     setTimeout(()=>{
          //         let scroll = document.getElementById('contentStart').offsetTop
          //         if(this.$screen.device==='mobile'){scroll=scroll-this.$ui.size.mobileNav}
          //         document.getElementById('showContent').scrollTo({top: scroll, behavior: 'smooth'})},500)
          // }
          //
          //
          // if(this.$route.path==='/'){
          //
          //
          //         setTimeout(()=>{
          //             if(this.$screen.scrollY<100){
          //                 let scroll = document.getElementById('contentStart').offsetTop
          //                 if(this.$screen.device==='mobile'){scroll=scroll-this.$ui.size.mobileNav}
          //                 document.getElementById('showContent').scrollTo({top: scroll, behavior: 'smooth'})
          //             }
          //
          //             },4000)
          //
          // }


      }
      this.$nextTick(async ()=>{
      this.$store.commit('updateScreen') // ОБНОВЛЯЕМ ПЕРВИЧНЫЕ РАЗМЕРА ОКНА БРАУЗЕРА
      this.$store.commit('updateColors',this.$color) // УСТАНОВКА ЦВЕТОВ ТЕМЫ

      // УСТАНАВЛИВАЕМ ТРИГГЕРЫ НА ИЗМЕНЕНИЯ В РАЗМЕРАХ ОКНА БРАУЗЕРА
      await window.addEventListener('resize', () => {  this.$store.commit('updateScreen') })
      await window.addEventListener('orientationchange', () => {this.$store.commit('updateScreen')})
      // await window.addEventListener('scroll', () => {this.$store.commit('updateScroll')})


        // return new Promise((resolve, reject) => {
        //   axios.post('https://san-elita.ru/api/', JSON.stringify(serverData),{headers: {'Content-Type': 'multipart/form-data'
        //     }}).then((response) => {
        //     this.reservationNumber = response.data.reservationNumber
        //     this.sent = true
        //
        //     console.log(response.data); resolve(response);
        //   }, error => {reject(error);})
        // })


    })
  }
}
</script>

<style>

    #mainWindow{position: relative; overflow: hidden !important}



</style>