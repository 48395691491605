<template>
  <div id="PromoBlock" v-if="visible" :class="animation" class="animate__animated" :style="{height:height+'px'}">
    <div class="content">
      <div class="header">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="m8.85 17.825l3.15-1.9l3.15 1.925l-.825-3.6l2.775-2.4l-3.65-.325l-1.45-3.4l-1.45 3.375l-3.65.325l2.775 2.425l-.825 3.575ZM5.825 22l1.625-7.025L2 10.25l7.2-.625L12 3l2.8 6.625l7.2.625l-5.45 4.725L18.175 22L12 18.275L5.825 22ZM12 13.25Z"/></svg>
        <span>АКЦИЯ</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="m8.85 17.825l3.15-1.9l3.15 1.925l-.825-3.6l2.775-2.4l-3.65-.325l-1.45-3.4l-1.45 3.375l-3.65.325l2.775 2.425l-.825 3.575ZM5.825 22l1.625-7.025L2 10.25l7.2-.625L12 3l2.8 6.625l7.2.625l-5.45 4.725L18.175 22L12 18.275L5.825 22ZM12 13.25Z"/></svg>
      </div>

      <p>с 20 ноября по 25 декабря - при бронировании на сайте - скидка 15%. </p>

    </div>
    <svg @click="hide()" class="hideButton" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"/></svg>

  </div>

</template>

<script>
  export default {
  name: 'PromoBlock',
  data: () => ({
    height:90,
    visible:false,
    animation: 'animate__fadeInUp'
  }),
  methods:{
    hide(){
      this.animation='animate__fadeOutDown'
    }
  },
  mounted() {
    // setTimeout(()=>{this.visible = true},1000)

  },
  computed:{}
}
</script>

<style scoped>
#PromoBlock{
  background-color: #c77a00;
  position: absolute;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  color: white;
  text-align: center;
  display: grid;
  align-content: center;
  padding: 10px;
}


#PromoBlock>.hideButton{
  position: absolute;
  right: 10px;
  top:10px;
  cursor: pointer;
}

#PromoBlock>.content>.header{
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 10px;
  justify-content: center;
}
#PromoBlock>.content>.header>span{
  font-weight: bold;
  font-size: 20px;
}

#PromoBlock>.content>.more{
  font-size: 12px;
  /*opacity: 0.5;*/
  padding-top: 10px;
  color: #ffbf9c;
}

</style>
