<template>
  <modal-block modal-id="ModalContacts" close-button="yes">
    <template v-slot:content>
      <div class="contentBox">
        <page-header iconsize="50" o icon="woman-call">
          <template v-slot:title>КОНТАКТНАЯ ИНФОРМАЦИЯ</template>
        </page-header>
        <div class="content" :class="$screen.device">

          <div class="contacts">
            <p class="title phones">ОТДЕЛ БРОНИРОВАНИЯ И РАЗМЕЩЕНИЯ</p>
            <p class="item phone">+7 (800) 201 27 25</p>
            <p class="item phone">+7 (8793) 35 23 22</p>
            <p class="item phone">+7 (928) 300 22 66</p>
            <p class="item phone">+7 (962) 024 77 81</p>

            <p class="title address">НАШ АДРЕС</p>
            <p class="item add">357700, Ставропольский край,</p>
            <p class="item add">город-курорт Кисловодск, ул. Линейная, 8</p>


          </div>

        </div>
      </div>
    </template>

  </modal-block>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import ModalBlock from "@/components/ModalBlock";
export default {
  name: 'ModalContacts',
  components: {ModalBlock, PageHeader,},
  data: () => ({}),
}
</script>


<style>
  #ModalContacts{max-width: 650px; width: 100%;}
  #ModalContacts.mobile{width: 100%; height: 100%}
  #ModalContacts>.contentBox{overflow-y: auto}
  #ModalContacts>.contentBox>.content{padding: 20px}
  #ModalContacts>.contentBox>.content.mobile{padding: 20px}
  #ModalContacts>.contentBox>.content>.contacts{display: grid; justify-content: center; text-align: center}
  #ModalContacts>.contentBox>.content>.contacts>.title{font-size: 20px; font-weight: lighter; padding-bottom: 10px; padding-top: 20px}

</style>