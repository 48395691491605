// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
export default createVuetify({
    theme: {
        defaultTheme: 'light',
        themes: {
            options: {customProperties: true,},
            light:{
                dark: false,
                colors: {
                    background: '#FFFFFF',
                    surface: '#FFFFFF',
                    primary: '#f5b11e',
                    secondary: '#03DAC6',
                    error: '#B00020',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FB8C00',
                    test:'#B00020'
                }
            },
        }
    }
})