<template>

  <modal-block modal-id="ModalMobileMenu" close-button="yes">
    <template v-slot:content>
      <div class="backTexture" :style="{backgroundImage: `url(${require('@/assets/img/pattern.png')})`}"></div>
      <div class="contentBox">
        <div class="MobileMenuContent" :style="{paddingTop:this.$screen.width<400?'10px':$ui.size.mobileNav+'px'}">
          <div class="logo"><img :style="{height:this.$screen.width<400?'80px':'100px'}" :src="require('@/assets/img/logoFlower.svg')"></div>
          <div class="links">
            <v-btn variant="text" @click="$commit('closeModal','ModalMobileMenu')" to="/"  :block="true" color="white">ГЛАВНАЯ</v-btn>
            <v-btn variant="text" @click="$commit('closeModal','ModalMobileMenu')" to="/rooms"  :block="true" color="white">НОМЕРА И ЦЕНЫ</v-btn>
            <v-btn variant="text"  @click="$commit('closeModal','ModalMobileMenu')" to="/treatment" :block="true" color="white">ЛЕЧЕНИЕ И ПУТЕВКИ</v-btn>
            <v-btn variant="text" @click="$commit('closeModal','ModalMobileMenu')" to="/spa" :block="true" color="white">СПА-ЗОНА</v-btn>
            <v-btn variant="text" @click="$commit('closeModal','ModalMobileMenu')" to="/leisure" :block="true" color="white">ПИТАНИЕ И ДОСУГ</v-btn>
          </div>
        </div>
      </div>
      <div class="contacts">
        <a href="tel:88002012725" class="con number" :style="{fontSize:this.$screen.width<400?'16px':'23px'}">
          <div  class="item">
            <v-icon color="green" :size="$screen.width<400?20:30">mdi-phone</v-icon><span>8 (800) 201 27 25</span>
          </div>
          <p class="label">ОТДЕЛ РАЗМЕЩЕНИЯ И БРОНИРОВАНИЯ</p>
        </a>

        <div class="address">
          <p>357700, Ставропольский край,</p>
          <p>г. Кисловодск, ул. Линейная, 8</p>
        </div>


      </div>
    </template>

  </modal-block>




</template>

<script>
  import ModalBlock from "@/components/ModalBlock";
  export default {
  name: 'ModalMobileMenu',
    components: {ModalBlock},
    data: () => ({showMenu:false}),
  methods:{toggle(){this.showMenu = !this.showMenu}},
  mounted() {},
}
</script>

<style scoped>
  .contacts{display: grid; align-content: center; padding: 10px; position: absolute; bottom: 10px; justify-self: center}

  .con{display: grid; color: white; transition-duration: 100ms; cursor: pointer; font-weight: bold; ;  display: grid; width: 100%; overflow: hidden; border-radius: 10px; padding-top: 8px; align-content: center;  background-color: rgba(0, 0, 0, 0.40);}
  .con>.item{ white-space:nowrap; padding-left: 10px; padding-right: 10px; display: grid; align-items: center; grid-template-columns: auto auto;justify-content: center; column-gap: 10px; padding-bottom: 4px }
  .con>.label{font-size: 10px; font-weight: normal; padding: 4px;  text-align: center; background-color: #bf8812}
  .address{color: #ffffff; font-size: 12px; text-align: center; padding-top: 14px}
</style>


<style>

  #ModalMobileMenu{width: 100%; height: 100%; background-color: rgba(85, 85, 85, 0.69); position: relative}
  #ModalCovid>.contentBox{overflow-y: auto; }
  #ModalCovid>.contentBox>.content{padding: 40px}
  #ModalCovid>.contentBox>.content.mobile{padding: 20px}
  #ModalMobileMenu>.backTexture{position: absolute;  background-repeat: repeat; width: 100%; height: 100%; zoom: 40%; opacity: 5%}


  .MobileMenuCloseButton{right: 10px;top:10px; position: absolute}

  .MobileMenuContent{width: 100%; height: 100%; background-color: rgba(20, 80, 34, 100); display: grid;   grid-template-rows: auto 1fr; padding-top: 20px}
  .MobileMenuContent>.logo{display: grid; justify-content: center; margin-bottom: 20px}
  .MobileMenuContent>.logo>img{}
</style>
