<template>
    <div id="FooterPanel">
        <div class="FooterContainer centerContent" :class="`${$screen.device}`" :style="{maxWidth:$ui.size.contentMaxWidth+'px'}">

            <div class="FooterSection one">
                <img class="logo" :src="require('@/assets/img/logoFlower.svg')"/>
                <p>ООО "САНАТОРИЙ ЭЛИТА"</p>
                <p>город-курорт Кисловодск</p>
                <p>ул. Линейная, д. 8</p>
            </div>


            <div class="FooterSection two">
                <div class="sitemap">

                    <router-link to="/rooms" class="link" href="">НОМЕРА И ЦЕНЫ</router-link>
                    <router-link to="/treatment" class="link" href="">ДОСУГ И РАЗВЛЕЧЕНИЯ</router-link>
                    <router-link to="/spa" class="link" href="">СПА-ПРОЦЕДУРЫ</router-link>
                    <router-link to="/leisure" class="link" href="">ДОСУГ И РАЗВЛЕЧЕНИЯ</router-link>
                </div>

            </div>
            <div class="FooterSection three">
                <router-link class="link" to="/rooms/standard">НОМЕР СТАНДАРТ</router-link>
                <router-link class="link" to="/rooms/comfortDBL">НОМЕР КОМФОРТ-ДАБЛ</router-link>
                <router-link class="link" to="/rooms/comfortTWIN">НОМЕР КОМФОРТ-ТВИН</router-link>
                <router-link class="link" to="/rooms/studio">НОМЕР СТУДИО</router-link>
                <router-link class="link" to="/rooms/lux">НОМЕР ЛЮКС</router-link>
            </div>


            <div class="FooterSection four">
                <p class="link" @click="$commit('openModal','ModalDocs')">ЛИЦЕНЗИИ И СЕРТИФИКАТЫ</p>
                <p class="link" @click="$commit('openModal','ModalPartners')">ДЛЯ ПАРТНЕРОВ И АГЕНСТВ</p>
                <p class="link" @click="$commit('openModal','ModalCovid')">ПРАВИЛА ПРИ COVID-19</p>
                <p class="link" @click="$commit('openModal','ModalCovid')">МОЯ БРОНЬ</p>
            </div>



        </div>
    </div>

</template>

<script>
    export default {
        name: 'FooterPanel',
        data: () => ({}),
        methods:{},
        mounted() {},}
</script>


<style scoped>
    #FooterPanel{width: 100%;background-color: #3e3e3e; display: grid}
    .FooterContainer{display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; width: 100%;  column-gap: 4px; align-items: flex-start}
    .FooterContainer.mobile{grid-template-columns: 1fr}
    .FooterSection{font-size: 11px; display: grid; padding: 20px; background-color: #454545; color: #9a9a9a; height: 100%; align-content: center}
    .FooterSection.one{justify-items: center}
    .FooterSection.two{}
    .FooterSection.two>.sitemap{display: grid; }
    .link{color: #9a9a9a; cursor: pointer}
    .link:hover{color: white}
    .FooterSection.three{}
    .FooterSection>.logo{width: 50px}
    .FooterContainer.mobile>.FooterSection{justify-content: center; text-align: center; font-size: 13px; padding: 10px}
    .FooterContainer.mobile>.FooterSection.one{order: 4}

</style>