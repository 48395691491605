<template>
            <div id="NavPanel" class="sticky"  :style="{height:height+'px', width:width+'px'}">
                <div class="bg"></div>
                <div class="backTexture" :style="{backgroundImage: `url(${require('@/assets/img/pattern.png')})`}"></div>
                <div class="content centerContent" :style="{maxWidth:$ui.size.contentMaxWidth+'px'}">
                    <router-link to="/" class="logo"><img class="logoImage" :src="require('@/assets/img/navLogoWide.svg')"/></router-link>
                    <div class="routes top" :style="{height:topMenuHeight+'px'}">
                        <div class="topMenu">

                        </div>
                        <div class="actions">
                          <div class="route" @click="$commit('openModal','ModalDocs')">
                            <v-btn size="x-small" variant="text" :style="{color:'white'}" class="navElement" color="white" :height="30"  :border="false">{{$screen.width>940? 'ЛИЦЕНЗИИ И СЕРТИФИКАТЫ': 'ЛИЦЕНЗИИ'}}</v-btn>
                          </div>

<!--                          <div class="route" @click="$commit('openModal','ModalPartners')">-->
<!--                            <v-btn size="x-small" variant="text" :style="{color:'white'}" class="navElement" color="white" :height="30"  :border="false">{{$screen.width>940? 'ПАРТНЕРАМ': 'ПАРТНЕРАМ'}}</v-btn>-->
<!--                          </div>-->

                          <div class="route" @click="$commit('openModal','ModalContacts')">
                            <v-btn size="x-small" variant="text" :style="{color:'white'}" class="navElement" color="white" :height="30"  :border="false">{{$screen.width>940? 'КОНТАКТЫ': 'КОНТАКТЫ'}}</v-btn>
                          </div>
<!--                            <v-btn size="small" @click="$commit('openModal','ModalMobileMenu')" :block="true" color="primary"><span style="color:white; font-size: 11px; display: grid; margin-bottom: 4px">МОЯ БРОНЬ</span></v-btn>-->
<!--                            <v-btn size="small" @click="$commit('openModal','ModalCovid')" :block="true" color="red"><span style="color:white; font-size: 11px; display: grid; margin-bottom: 4px">ПРАВИЛА ПРИ COVID-19</span></v-btn>-->
                        </div>


                    </div>
                </div>
                <div class="sub centerContent" :style="{maxWidth:$ui.size.contentMaxWidth+'px'}">
                    <div class="routes sub">
                        <v-btn :block="true"  to="/" class="navElement" color="primary" :height="subMenuHeight"  :border="false"><v-icon color="white" size="24" icon="mdi-home"/></v-btn>
                        <div class="subMenu">
                            <div class="route" v-for="(r,rIndex) in navSubElements" :key="rIndex">
                                <v-btn :block="true"  :to="r.route" class="navElement" :color="$state.currentRoute === r.route ? 'black' :'white'" :height="subMenuHeight" :border="false">{{$screen.width>940? r.name: r.shortName}}</v-btn>
                            </div>
<!--                            <div class="route">-->
<!--                                <v-btn :block="true" class="navElement" color="green" :height="subMenuHeight" :border="false"><span style="color: white">КОНТАКТЫ</span></v-btn>-->
<!--                            </div>-->
                        </div>

                    </div>
                </div>
            </div>

</template>

<script>

  let navTopElementsItems =[
      {name:'ЛИЦЕНЗИИ И СЕРТИФИКАТЫ', shortName:'ЛИЦЕНЗИИ',route:'rooms'},
      {name:'ПАРТНЕРАМ', shortName:'ПАРТНЕРАМ',route:'medicine'},
      {name:'КОНТАКТЫ', shortName:'КОНТАКТЫ',route:'fun'},
  ]

  let navSubElementsItems =[
      {name:'Номера и цены', shortName:'Номера', route:'/rooms'},
      {name:'Лечение и Путевки', shortName:'Лечение',route:'/treatment'},
      {name:'СПА-ПРОЦЕДУРЫ',shortName:'СПА',route:'/spa'},
      {name:'ПИТАНИЕ И ДОСУГ',shortName:'ПИТАНИЕ И ДОСУГ', route:'/leisure'},
  ]

  export default {
  name: 'NavPanel',
  data: () => ({
      route: undefined,
      height:90,
      topMenuHeight:50,
      subMenuHeight:40,
      navTopElements:navTopElementsItems,
      navSubElements:navSubElementsItems
  }),
  methods:{
      goTo(route){
          this.$router.push(route)
      },
      scrollUp(){document.getElementById('showContent').scrollTo({top: 0, behavior: 'smooth'})}},
  mounted() {
      this.height=this.$ui.size.navPanel[this.$screen.device]
      this.route = this.$route
      },
  computed:{
      width(){
          let width = this.$screen.width-this.$ui.size.scrollbarWidth
          if(this.$screen.menuStickyPoint){width = this.$screen.width-this.$ui.size.scrollbarWidth}


          return width

      },
      classObject(){
              return {
                  'overlayLock': this.$store.state.overlayLock && this.$screen.scrollYbeforeLock>this.$screen.height,
              }
          }
  }
}
</script>

<style scoped>
    #NavPanel{z-index:10;width: 100%; display: grid; overflow: hidden;   position: absolute;}
    #NavPanel>.bg{position: absolute; background-color: #39451e; width: 100%; height: 100%; opacity: 80%; transition-duration: 300ms;}
    #NavPanel>.backTexture{position:absolute; background-repeat: repeat; width: 100%; height: 100%; zoom: 40%; opacity: 30%}
    #NavPanel.sticky>.bg{background-color: #39451e; opacity: 100%;}


    #NavPanel>.content{z-index: 4; width: 100%;height: 100%; grid-auto-flow: column; overflow: hidden;     display: grid; grid-template-columns: 1fr auto; column-gap: 20px}
    #NavPanel>.content>.logo{display: grid;  align-content: center;     justify-content: flex-start;}
    #NavPanel>.content>.logo>.logoImage{height: 96%; width: 100%}
    #NavPanel>.sub{width: 100%; height: 100%; grid-auto-flow: column; display: grid; border-top-left-radius: 10px;overflow: hidden}
    .topMenu{width: 100%;height: 30px; grid-auto-flow: column; display: grid; }
    .subMenu{width: 100%;height: 100%; grid-auto-flow: column; display: grid;overflow: hidden}
    .routes.top{display: grid;  justify-content: right;  align-items: center}
    .routes.top>.actions{display: grid;  grid-auto-flow: column; column-gap: 1px;   width: 100%;  align-items: center; padding-right: 0px; margin-top: -13px; background-color: #1f1f1f; border-top-left-radius: 10px;border-top-right-radius: 10px}
    .routes.sub{display: grid; grid-auto-flow: column; grid-template-columns: auto 1fr}
    .routes>.route{display: grid; grid-auto-flow: column}
    .navElement{border-radius: 0px!important; }

</style>
