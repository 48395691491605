<template>
  <modal-block modal-id="ModalBooking" close-button="yes">
    <template v-slot:content>

      <div class="bookingBox">
        <page-header :iconsize="this.$screen.height>600?50:30" icon="hotel-receptionist">
          <template v-slot:title>ЗАПРОС НА БРОНИРОВАНИЕ</template>
          <template v-slot:text>
            <span  style="font-size: 20px; font-weight: lighter;">НОМЕР  </span>
            <span style="font-size: 20px; font-weight: bold; text-transform: uppercase">«{{$store.state.rooms[$store.state.bookingRoom].name}}»</span>
          </template>
        </page-header>
        <div v-if="sent" class="success">
          <p class="title">ВАША ЗАЯВКА УСПЕШНА ОТПРАВЛЕНА</p>
          <p class="subtitle">НОМЕР ВАШЕЙ БРОНИ</p>
          <p class="title" style="color: #4eb734 !important;">{{reservationNumber}}</p>
          <p class="subtitle">В БЛИЖАЙШЕЕ ВРЕМЯ С ВАМИ СВЯЖЕТСЯ НАШ МЕНЕДЖЕР</p>
        </div>

        <div v-if="!sent" class="form">
          <div class="pre">
            <p class="one">НА ДАННОМ ЭТАПЕ ОТ ВАС НЕ ТРЕБУЕТСЯ ВНОСИТЬ ОПЛАТУ ИЛИ ПРЕДОСТАВЛЯТЬ ДАННЫЕ БАНКОВСКОЙ КАРТЫ</p>
          </div>
          <div class="dates">
            <div class="date">
              <div class="label"><img class="icon" :src="require('@/assets/icons/date.png')"><p class="name">ДАТА ЗАЕЗДА</p></div>
              <v-date-picker color="yellow" :min-date='checkInMinDay' v-model="checkIn" :input-debounce="500">
                <template v-slot="{ inputValue, inputEvents }">
                  <input :class="{invalid:!inputValid.checkIn}" @focus="inputValid.checkIn=true, valid=true"  class="pickerInput bg-white borderrounded"
                         :value="inputValue" @click="inputValid.checkIn=true,valid=true"
                         v-on="inputEvents"
                         readonly
                  />
                </template>
              </v-date-picker>
            </div>

            <div class="date">
              <div class="label"><img class="icon" :src="require('@/assets/icons/date.png')"><p class="name">ДАТА ВЫЕЗДА</p></div>
              <v-date-picker  color="yellow" :min-date='checkOutMinDay' v-model="checkOut" :input-debounce="500">
                <template v-slot="{ inputValue, inputEvents }">
                  <input  :class="{invalid:!inputValid.checkOut}" @focus="inputValid.checkOut=true, valid=true" class="pickerInput bg-white borderrounded"
                          :value="inputValue" @click="inputValid.checkOut=true,valid=true"
                          v-on="inputEvents"
                          readonly
                  />
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="error">
            <p class="valid" v-if="valid">УКАЖИТЕ АКТУАЛЬНЫЕ КОНТАКТНЫЕ ДАННЫЕ</p>
            <p v-if="!valid" class="invalidLabel">ЗАПОЛНИТЕ ВСЕ ПОЛЯ ОТМЕЧЕННЫЕ КРАСНЫМ</p>
          </div>
          <div class="client">
            <input @focus="inputValid.firstName=true, valid=true" placeholder="ВАШЕ ИМЯ" :class="{invalid:!inputValid.firstName}" class="pickerInput" v-model="firstName"/>
            <input @focus="inputValid.secondName=true, valid=true" placeholder="ВАШЕ ФАМИЛИЯ" :class="{invalid:!inputValid.secondName}"  class="pickerInput" v-model="secondName"/>
            <input @focus="inputValid.phone=true, valid=true" placeholder="НОМЕР ТЕЛЕФОНА" :class="{invalid:!inputValid.phone}" class="pickerInput" v-model="phone"/>
            <input @focus="inputValid.email=true, valid=true" placeholder="E-MAIL (НЕОБЯЗАТЕЛЬНО)" :class="{invalid:!inputValid.email}"  class="pickerInput" v-model="email"/>
          </div>


          <div class="submit">
            <v-btn :block=true @click="sendMail" width="200" variant="contained-flat" :border="false"
                   :style="{fontSize:$screen.width<375?'9px':'12px'} " height="40" size="small" color="primary">
              <span  style="color: white">ОТПРАВИТЬ</span>
            </v-btn>

          </div>
          <div class="pre">
            <p class="two">УКАЖИТЕ ДАТЫ И СВОИ КОНТАКТНЫЕ ДАННЫЕ И НАШ МЕНЕДЖЕР СВЯЖЕТСЯ С ВАМИ ДЛЯ ПОДТВЕРЖДЕНИЯ НАЛИЧИЯ МЕСТ И ОТВЕТИТ НА ВСЕ ВАШИ ВОПРОСЫ</p>
          </div>
        </div>



      </div>

    </template>

  </modal-block>

</template>

<script>

  const axios = require('axios');
  import ModalBlock from "@/components/ModalBlock";
  import PageHeader from "@/components/PageHeader";
  export default {
    name: 'ModalBooking',
    components: {PageHeader, ModalBlock},
    data: () => ({
      checkIn:'',
      checkOut:'',
      checkInMinDay:'',
      checkOutMinDay:'',
      firstName:'',
      secondName:'',
      phone:'',
      email:'',
      sent:false,
      inputValid:{
        checkIn:true,
        checkOut:true,
        firstName:true,
        secondName:true,
        phone:true,
        email:true,
      },
      valid:true,
      reservationNumber:undefined,

    }),
    mounted() {
      this.checkInMinDay =this.$moment().add(1,'day').toDate();
      this.checkOutMinDay = this.$moment().add(3,'day').toDate();
    },
    beforeUnmount() {
      this.sent = false
      this.reservationNumber = undefined
    },
    methods:{
      sendMail(){
        let room = this.$store.state.bookingRoom[0]
        let requestData = {
          roomType:room,
          checkIn:this.$moment(this.checkIn).format('YYYY-MM-DD'),
          checkOut:this.$moment(this.checkOut).format('YYYY-MM-DD'),
          firstName:this.firstName,
          secondName:this.secondName,
          phone:this.phone,
          email:this.email,
        }

        let valid = true
        let errors=0



        if(requestData.checkIn==='Invalid date'){this.inputValid.checkIn = false; errors++}
        if(requestData.checkOut==='Invalid date'){this.inputValid.checkOut = false; errors++}
        if(requestData.firstName.length<2){this.inputValid.firstName = false; errors++}
        if(requestData.secondName.length<2){this.inputValid.secondName = false; errors++}
        if(requestData.phone.length<2){this.inputValid.phone = false; errors++}

        if(errors>0){valid=false; this.valid=false}

        if(valid){

          requestData.checkIn =requestData.checkIn+' 12:00:00'
          requestData.checkOut =requestData.checkOut+' 10:00:00'
          requestData.roomType = this.$store.state.rooms[requestData.roomType].name

          setTimeout(()=>{
            let serverData={
              data:requestData
            }

            return new Promise((resolve, reject) => {
              axios.post('https://san-elita.ru/api/', JSON.stringify(serverData),{headers: {'Content-Type': 'multipart/form-data'
                }}).then((response) => {
                this.reservationNumber = response.data.reservationNumber
                this.sent = true

                console.log(response.data); resolve(response);
                  }, error => {reject(error);})
            })



          },200)
        }
      },
      dateChange(picker){
        let minStay = 3
        if(picker==='checkIn'){
          if(this.checkOut!==''){
            let b = this.$moment(this.checkIn);
            let a = this.$moment(this.checkOut);
            let dif = a.diff(b, 'days')
            if(dif<minStay){this.checkOut =''}
          }
        }
      }
    },
    watch:{
      checkIn() {this.dateChange('checkIn')},
      checkOut() {this.dateChange('checkOut')}
    }
  }
</script>

<style scoped>
  .invalid{
    border: 1px solid red;
  }

  .error{display: grid; text-align: center; height: 20px; font-size: 12px}
  .error>.invalidLabel{ font-size: 12px; color: red; font-weight: bold}

</style>

<style>

  #ModalBooking{max-width: 650px; width: 100%;}
  #ModalBooking.mobile{width: 100%; height: 100%; }
  .bookingBox{width: 100%;overflow: auto; position: relative}

  .bookingBox>.form>.pre{display: grid; padding: 0px 20px;font-size: 12px; text-align: center}
  .bookingBox>.form>.pre>.one{font-weight: bold; color: #23b301; font-size: 14px  }
  .bookingBox>.form>.pre>.two{padding-bottom: 10px}

  .bookingBox>.form>.dates{display: grid; grid-template-columns: 1fr 1fr; column-gap: 10px; padding: 20px ;padding-top: 10px; padding-bottom: 5px;}
  .bookingBox>.form>.dates>.date{display: grid; row-gap: 8px; padding-top: 10px;}
  .bookingBox>.form>.dates>.date>.label{grid-template-columns: auto auto; display: grid; column-gap: 8px; justify-content: center; width: 100%}
  .bookingBox>.form>.dates>.date>.label>.icon{height: 18px;}
  .bookingBox>.form>.dates>.date>.label>.name{font-size: 14px;}
  .bookingBox>.form>.dates>.date{}
  .pickerInput{width: 100%; height: 40px; text-align: center; background-color: white}
  .bookingBox>.form>.client{margin:10px 0px; display: grid; row-gap: 10px; padding: 20px; padding-top: 0px; padding-bottom: 0px; }
  .bookingBox>.form>.submit{padding: 20px; padding-top: 0px}
  .bookingBox>.success{display: grid; justify-items: center; padding: 20px}
  .bookingBox>.success>.title{font-weight: bold; font-size: 30px}
  .bookingBox>.success>.subtitle{ font-size: 20px}

</style>