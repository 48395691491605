<template>
  <modal-block modal-id="ModalPartners" close-button="yes">
    <template v-slot:content>
      <div class="contentBox">
        <page-header iconsize="50" o icon="man-in-suit">
          <template v-slot:title>ПАРТНЕРАМ И АГЕНТСТВАМ</template>
        </page-header>
        <div class="content" :class="$screen.device">
        </div>
      </div>
    </template>

  </modal-block>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import ModalBlock from "@/components/ModalBlock";
export default {
  name: 'ModalPartners',
  components: {ModalBlock, PageHeader,},
  data: () => ({}),
}
</script>


<style>
  #ModalPartners{max-width: 650px; width: 100%;}
  #ModalPartners.mobile{width: 100%; height: 100%}
  #ModalPartners>.contentBox{overflow-y: auto}
  #ModalPartners>.contentBox>.content{padding: 20px}
  #ModalPartners>.contentBox>.content.mobile{padding: 20px}

</style>