import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'


const routes = [
  {path: '/', name: 'main', component: () => import('../pages/MainPage.vue')},
  {path: '/treatment', name: 'treatment', component: () => import('../pages/TreatmentPage.vue')},
  {path: '/spa', name: 'spa', component: () => import('../pages/SpaPage.vue')},
  {path: '/leisure', name: 'leisure', component: () => import('../pages/LeisurePage.vue')},
  {path: '/rooms/:selectedRoom', component: () => import('../pages/RoomsPage.vue'), props:true},
  {path: '/rooms', name: 'rooms', component: () => import('../pages/RoomsPage.vue')},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  store.commit('updateRoute',to.path)
})

router.afterEach((to,from)=>{

  let mainTitle ='САНАТОРИЙ ЭЛИТА - КИСЛОВОДСК - ОФИЦИАЛЬНЫЙ САЙТ'
  let titles = {
    '/':'ГЛАВНАЯ СТРАНИЦА',
    '/treatment':'ГЛАВНАЯ СТРАНИЦА', '/treatment/':'ЛЕЧЕНИЕ И ПУТЕВКМ',
    '/spa':'СПА-КОМПЛЕКС', '/spa/':'СПА-КОМПЛЕКС',
    '/leisure/':'ПИТАНИЕ И ДОСУГ', '/leisure':'ПИТАНИЕ И ДОСУГ',
    '/rooms':'НОМЕРА И ЦЕНЫ', '/rooms/':'НОМЕРА И ЦЕНЫ',
    '/rooms/standard':'НОМЕРА И ЦЕНЫ', '/rooms/standard/':'НОМЕРА И ЦЕНЫ',
    '/rooms/comfort':'НОМЕРА И ЦЕНЫ', '/rooms/comfort/':'НОМЕРА И ЦЕНЫ',
    '/rooms/studio':'НОМЕРА И ЦЕНЫ','/rooms/studio/':'НОМЕРА И ЦЕНЫ',
    '/rooms/lux':'НОМЕРА И ЦЕНЫ', '/rooms/lux/':'НОМЕРА И ЦЕНЫ',
  }


  document.title = mainTitle+'-'+titles[to.path]


  setTimeout(()=> {
    if((to.path !=='/' && to.path!==from.path) || to.path ==='/' && from.path!=='/' || (to.path!=='/'&& from.path!=='/') & to.path===from.path ||  (to.path ==='/' && from.path=='/' && store.state.screen.scrollY>40)){
      let scroll = document.getElementById('contentStart').offsetTop
      if(store.state.screen.device==='mobile'){scroll=scroll-store.state.ui.size.mobileNav}
      document.getElementById('showContent').scrollTo({top: scroll,behavior:'smooth'})
    }

  },400)


})

  export default router
