<template>
  <div id="showContent" v-scroll.self="onScroll"  :style="{height:$screen.height+'px'}">
      <MobileNav />
    <div class="content" :style="{height:$screen.height-$ui.size.mobileNav+'px'}">
      <scroll-up/>
      <span id="contentStart" class="anchor "></span>
      <div id="content" :class="$screen.device"><router-view/></div>
      <map-panel/>
      <footer-panel/>
    </div>
  </div>

</template>

<script>

  import ScrollUp from "@/components/ScrollUp";
  import MapPanel from "@/components/MapPanel";
  import FooterPanel from "@/components/FooterPanel";
  import MobileNav from "@/components/MobileNav";
  export default {
    name: 'MobileView',
    components: {MobileNav, FooterPanel, MapPanel,   ScrollUp},
    data: () => ({

    }),
    methods:{
      onScroll (e) {
        this.$commit('updateScroll',e.target.scrollTop)
      }
    }
  }
</script>

<style scoped>
  #showContent{display: grid; grid-template-rows: auto 1fr;overflow: auto;}
  #content{padding-top: 60px}

</style>