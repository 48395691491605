<template>
  <div id="showContent" v-scroll.self="onScroll" :style="{height:$screen.height+'px', width:$screen.width+'px'}">
    <span class="anchor" id="contentStart"></span>
    <div id="NavContainer">
    </div>
    <div id="content" :class="$screen.device"><router-view/></div>
    <map-panel/>
    <footer-panel/>
  </div>
</template>

<script>

  import MapPanel from "@/components/MapPanel";
  import FooterPanel from "@/components/FooterPanel";
  export default {
    name: 'MainView',
    components: { FooterPanel, MapPanel, },
    data: () => ({}),
    methods:{
      onScroll (e) {
        this.$commit('updateScroll',e.target.scrollTop)
      }
    }
  }
</script>

<style scoped>
  #showContent{
    overflow: auto;
    position: relative;
    padding-top: 20px;
  }

  #NavContainer{height: 90px}
</style>
