<template>
    <v-fade-transition>
        <div :class="`ModalContainer ${$screen.device}`" v-if="$store.state.modals[modalId]">
            <div class="dimmer"></div>
            <div :id="modalId" class="ModalContent" :class="$screen.device" :style="{maxHeight:contentHeight+'px'}">
                <div v-if="closeButton" class="closeButton"><v-btn @click="$store.commit('closeModal',modalId)" icon="mdi-close" size="small" color="primary"/> </div>
                    <slot name="content"></slot>
            </div>
        </div>
    </v-fade-transition>
</template>

<script>
    export default {
        name: 'ModalBlock',
        data: () => ({}),
        props:['modalId','closeButton'],
        methods:{},
        mounted() {},
        computed:{
            contentHeight(){
                let height = this.$screen.height-50
                if(this.$screen.device==='mobile'){height=this.$screen.height}
                if(this.$screen.height<600){height=this.$screen.height}
                return height

            }
        }
    }

</script>


<style>
    .ModalContainer{width: 100%;height: 100%;display: grid; position: absolute;}
    .ModalContainer>.dimmer{background-color: black;opacity: 70%; position: absolute; height: 100%; width: 100%}
    .ModalContent{background-color: #f5eeeb;display: grid ;position: relative;grid-template-rows: 1fr auto;border-radius: 4px;justify-self: center; align-self: center}
    .ModalContent.mobile{border-radius: 0px; width: 100%; height: 100%; overflow: scroll}
    .ModalContent>.closeButton{position: absolute; right: 10px;top:10px;display: grid; z-index: 10}

</style>