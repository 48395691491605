<template>
  <modal-block modal-id="ModalCovid" close-button="yes">
    <template v-slot:content>
      <div class="contentBox">
        <page-header iconsize="80" o icon="covid">
          <template v-slot:title>ППРАВИЛА РАЗМЕЩЕНИЯ ОТДЫХАЮЩИХ В РЕЖИМЕ ПРОТИВОДЕЙСТВИЯ COVID-19</template>
        </page-header>
        <div class="content" :class="$screen.device">
          <p class="subTitle">Заселение осуществляется при условии предъявления следующих документов:</p>
          <p class="covidRule">
            <span class="dot">  + </span>
            <span>QR-код о вакцинации или  Медицинский документ о перенесенном заболевании в течение
            предшествующих 6 месяцев или Медицинский документ, подтверждающий отрицательный результат
            лабораторного исследования материала на новую коронавирусной инфекцию
            (COVID-19) методом полимеразной цепной реакции (ПЦР), выданного не
              ране чем за три календарных дня до заселения.
            </span>
          </p>

          <p class="covidRule">
            <span class="dot">  + </span>
            <span>Справка ( заключение) врача-эпидемиолога или врача-педиатра/врача-терапевта/врача общей практики (семейного врача) об отсутствии контакта с больными инфекционными заболеваниями в течении предшествующих 14 календарных дней, выданной не позднее, чем за 3 календарных дня до заезда в санаторий
            </span>
          </p>

          <p class="covidRule">
            <span class="dot">  + </span>
            <span>Санаторно-курортная карта с обязательным указанием результата флюорографического исследования.</span>
          </p>

        </div>
      </div>
    </template>

  </modal-block>

</template>

<script>

import PageHeader from "@/components/PageHeader";
import ModalBlock from "@/components/ModalBlock";
export default {
  name: 'ModalCovid',
  components: {ModalBlock, PageHeader,},
  data: () => ({}),
}
</script>


<style>
  #ModalCovid{max-width: 650px; width: 100%;}
  #ModalCovid.mobile{width: 100%; height: 100%}
  #ModalCovid>.contentBox{overflow-y: auto}
  #ModalCovid>.contentBox>.content{padding: 40px}
  #ModalCovid>.contentBox>.content.mobile{padding: 20px}

  #ModalCovid>.contentBox>.content>.subTitle{font-weight: bold; font-size: 18px}
  #ModalCovid>.contentBox>.content>.covidRule{ font-size: 14px; margin-top: 20px}
  #ModalCovid>.contentBox>.content>.covidRule>.dot{background-color: red; color: white; padding-left: 3px; margin-right: 4px; height: 3px; width: 3px}
</style>