<template>
  <div id="OverlaysView" :class="{mobile:$screen.device==='mobile',active:$store.state.overlayLock}">
    <modal-mobile-menu/>
    <modal-covid/>
    <modal-med-rules/>
    <modal-med-personal/>
    <modal-med-price/>
    <modal-med-paid-service/>
    <modal-booking/>
    <modal-room-price/>
    <modal-contacts/>
    <modal-docs/>
    <modal-partners/>
  </div>
</template>

<script>
  import ModalCovid from "@/modals/ModalCovid";
  import ModalMobileMenu from "@/modals/ModalMobileMenu";
  import ModalMedRules from "@/modals/ModalMedRules";
  import ModalMedPersonal from "@/modals/ModalMedPersonal";
  import ModalMedPrice from "@/modals/ModalMedPrice";
  import ModalMedPaidService from "@/modals/ModalMedPaidService";
  import ModalBooking from "@/modals/ModalBooking";
  import ModalRoomPrice from "@/modals/ModalRoomPrice";
  import ModalContacts from "@/modals/ModalContacts";
  import ModalDocs from "@/modals/ModalDocs";
  import ModalPartners from "@/modals/ModalPartners";
  export default {
    name: 'OverlaysView',
    components: {
      ModalPartners,
      ModalDocs,
      ModalContacts,
      ModalRoomPrice,
      ModalBooking,
      ModalMedPaidService, ModalMedPrice, ModalMedPersonal, ModalMedRules, ModalMobileMenu, ModalCovid},
    data: () => ({}),
    mounted() {this.$commit('setOverlayReady')}
  }
</script>

<style>

  #OverlaysView{position: absolute; top:0; left: 0; width: 100%; height: 100%; z-index: 100; pointer-events: none; display: grid}
  #OverlaysView.active{pointer-events: initial;}

</style>