<template>
        <div id="MobileNav" class="MobileNav onContent"  :style="{height: $ui.size.mobileNav+'px'}" >
            <div class="backTexture" :style="{backgroundImage: `url(${require('@/assets/img/pattern.png')})`}"></div>
            <div class="Nav" :style="{height: $ui.size.mobileNav+'px'}">
                <div class="logo" @click="$router.push('/')"><img class="logoImage" :src="require('@/assets/img/navLogoWide.svg')"/></div>
                <v-btn @click="$commit('openModal','ModalMobileMenu')"  color="primary" :height="$ui.size.mobileNav" rounded="0" ><v-icon size="30" icon="mdi-menu" color="white"></v-icon></v-btn>
<!--                <v-btn v-if="$store.state.modals.ModalMobileMenu"  @click="$commit('closeModal','ModalMobileMenu')"  color="primary" :height="$ui.size.mobileNav" rounded="0" ><v-icon size="30" icon="mdi-close" color="white"></v-icon></v-btn>-->
            </div>
        </div>

</template>

<script>

  export default {
  name: 'MobileNav',
  data: () => ({}),
  methods:{},
  mounted() {},
}
</script>

<style scoped>

  .MobileNav{position: absolute; pointer-events: all; z-index: 10; top: 0; cursor: pointer; background-color: rgba(57, 69, 30, 0.25); width: 100%; height: 40px; transition-duration: 400ms}
  .MobileNav.onContent{background-color: #39451e;}
  .MobileNav.is-sticky{}
  .MobileNav>.backPlate{pointer-events:none; background-color: #39451e;; height: 300px; width: 100%; z-index: -100}
  .MobileNav>.backTexture{pointer-events:none;  position: absolute;  background-repeat: repeat; width: 100%; height: 100%; zoom: 40%; opacity: 30%}
  .MobileNav>.Nav{z-index: 10;display: grid; grid-template-columns: 1fr auto ;height: 40px;width: 100%;position: absolute;top:0;left: 0;overflow: hidden;}

  .MobileNav>.Nav>.logo{padding-left: 10px; padding-right: 10px; display: grid; align-content: center;color: white}
  .MobileNav>.Nav>.logo>.logoImage{width: 80%}
</style>
